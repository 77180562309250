import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h2: "h2",
    h3: "h3",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Read the initial decisions, architecture, then the guides."
    }), "\n", _jsx(_components.h2, {
      id: "contributing",
      children: _jsx(_components.a, {
        href: "#contributing",
        children: "Contributing"
      })
    }), "\n", _jsx(_components.p, {
      children: "Anyone can contribute to these docs! They are simply Markdown files."
    }), "\n", _jsx(_components.h3, {
      id: "vscode",
      children: _jsx(_components.a, {
        href: "#vscode",
        children: "VSCode"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Recommended: use the ", _jsx(_components.a, {
          href: "https://marketplace.visualstudio.com/items?itemName=unifiedjs.vscode-mdx",
          children: "official extension"
        })]
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
